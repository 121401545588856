import React from "react";

export default function About() {
  return (
    <section className="about" id="about">
      <h2>Lidt om mig</h2>
      <p>
        Mit ønske er at skabe et sted for dig og din ugentlige pilatestræning i
        hyggelige omgivelser, hvor du altid føler dig velkommen. Her skal være
        plads til nærvær og fordybelse, et sted hvor du kan blive stærkere,
        smidigere og priotere dig selv lige her og nu.
      </p>
      <p>
        Jeg har selv dyrket pilates i 18 år og blev certificeret i 2017 fra
        Tromborg Pilates uddannelse i Silkeborg.
      </p>
      <p>
        Pilates er en træningsform, som styrker krop og sind, samtidigt med at
        udfordringerne i øvelserne hele tiden udvikles og varieres. Jeg træner
        små hold med max 9 deltagere og det giver optimale forudsætninger for
        effektiv træning.
      </p>
      <p>ALLE- uanset alder og fysik kan have glæde og gavn af pilates.</p>
    </section>
  );
}
