import React from "react";
import Spring from "../images/spring.jpg";
import Summer from "../images/summer.jpg";
import Fall from "../images/fall.jpg";
import Winter from "../images/winter.jpg";

export default function Season() {
  return (
    <section className="seasonContainer" id="season">
      <div>
        <img src={Winter} alt="season" className="seasonImg" />
        <p>Vintersæson starter d 6 Januar 2025</p>
        <p>Sæsonnen er 10 uger fra uge 2-11.</p>
        <p>10 ugers træning med en ugentlig træningsdag til 1100 kr.</p>
        <p>Lukket i uge 12.</p>
      </div>
      <div>
        <img src={Spring} alt="season" className="seasonImg" />
        <p>Forår/ sommer sæson starter d 24 Marts.</p>
        <p>Sæsonnen er 12 uger fra uge 13-26.</p>
        <p>
          Lukket: Skærtorsdag, 2 påskedag, Kristi himmelfartsdag, 1 pinsedag.
        </p>
        <p>12 ugers træning med 1 ugentlig træningsdag til 1320 kr</p>
      </div>
    </section>
  );
}
