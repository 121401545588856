import React from "react";

import Tina from "../images/tina.jpg";
import Facebook_tiny from "../images/facebook_tiny.png";
import Adresse from "../images/adresse.png";
import Phone from "../images/phone.png";

export default function Contact() {
  return (
    <section className="contact" id="contact">
      <h2>Kontakt</h2>
      <div className="contactContent">
        <div className="contactContent1">
          <img src={Tina} alt="Tina Lybæk" />
        </div>
        <div className="contactContent2">
          <p>
            <img src={Facebook_tiny} alt="Facebook_tiny" />
            Facebook: Pilates v/Tina Lybæk Grønne eller{"\u00A0"}
            <a
              href="https://www.facebook.com/pilatesvtina/"
              target="_blank"
              rel="noreferrer"
            >
              HER
            </a>
          </p>
          <p>
            <img src={Phone} alt="Phone" />
            Mobil Nummer: 40 14 61 67
          </p>
          <p>
            <img src={Adresse} alt="Adresse" />
            Adresse:
            <a
              href="https://www.google.com/maps/place/Essen+15,+6000+Kolding/@55.4617766,9.4805646,17z/data=!3m1!4b1!4m6!3m5!1s0x464ca1c19980e293:0xa06324524be98f3f!8m2!3d55.4617736!4d9.4831395!16s%2Fg%2F11bw43b_gl?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoJLDEwMjExMjM0SAFQAw%3D%3D"
              alt="addresse"
              target="_blanc"
            >
              Essen 15, 6000 Kolding
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
