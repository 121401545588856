import { HashLink } from "react-router-hash-link";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Program from "./components/Program";
import Contact from "./components/Contact";
import Team from "./components/Team";
import Price from "./components/Price";
import Season from "./components/Season";

import Logo from "./images/logo.png";

function App() {
  return (
    <>
      <Header />
      <section className="heroContainer" id="hero">
        <div className="heroTitle">
          <img src={Logo} alt="logo" />
          <h1>PILATES KOLDING</h1>
          <h2>V/ Tina Lybæk Grønne</h2>
        </div>
        <div className="heroBottom">
          <BrowserRouter>
            <HashLink smooth to="#season">
              <h3>Vinter 2025</h3>
              <svg class="arrows">
                <path class="a1" d="M0 0 L30 32 L60 0"></path>
                <path class="a2" d="M0 20 L30 52 L60 20"></path>
                <path class="a3" d="M0 40 L30 72 L60 40"></path>
              </svg>
            </HashLink>
            <HashLink smooth to="#season">
              <h3>Forår 2025</h3>
              <svg class="arrows">
                <path class="a1" d="M0 0 L30 32 L60 0"></path>
                <path class="a2" d="M0 20 L30 52 L60 20"></path>
                <path class="a3" d="M0 40 L30 72 L60 40"></path>
              </svg>
            </HashLink>
          </BrowserRouter>
        </div>
      </section>
      <Season />
      <Team />
      <Price />
      <Program />
      <About />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
